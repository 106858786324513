<script>
import axios from "axios";

import Layout from '@layouts/main'

import { setComputed } from '@state/helpers'

import _groupBy from 'lodash/groupBy'
import _uniq from 'lodash/uniq'
import _concat from 'lodash/concat'

import BranchBanner from "@components/BranchBanner";

import { getScrollTop } from '@utils/scroll'

export default {
  components: { Layout, BranchBanner },
  props: {
    branchList: {
      type: Array,
      required: true,
    },
    branchItem: {
      type: Object,
      required: true,
    },
    banner: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      menuIsOpen: false,
      dropDownIsOpen: false,
      paramsList: [
        {
          title: this.$i18n.t('global.PARKING'),
          alias: 'parking_car',
          icon: require('@assets/images/branch/icon_parking_car.png'),
          unit: 'boolean',
        },
        {
          title: this.$i18n.t('global.PRIVATE_ROOM'),
          alias: 'private_room',
          icon: require('@assets/images/branch/icon_private_room.png'),
          unit: '個',
        },
        {
          title: this.$i18n.t('global.SINGLE_SEAT'),
          alias: 'single_seat',
          icon: require('@assets/images/branch/icon_single_seat.png'),
          unit: '個',
        },
        {
          title: this.$i18n.t('global.TOTAL_SEAT'),
          alias: 'total_seat',
          icon: require('@assets/images/branch/icon_total_seat.png'),
          unit: '個',
        },
        {
          title: this.$i18n.t('global.1F_SEAT'),
          alias: '1f_seat',
          icon: require('@assets/images/branch/icon_1f_seat.svg'),
          unit: '席',
        },
        {
          title: this.$i18n.t('global.2F_SEAT'),
          alias: '2f_seat',
          icon: require('@assets/images/branch/icon_2f_seat.svg'),
          unit: '席',
        }
      ],
      scrollTop: this.getScrollTop,
    }
  },
  computed: {
    menuGroup() {
      return _groupBy( this.branchList, 'extrafields.store_location.value')
    },
    menuGroupSort() {
      const sortList = this.currentLanguage === "zh-TW"? ['北區', '中區', '南區']:['Taipei', 'Taichung'];
      const branchList = this.menuGroup;
      return _uniq(_concat(sortList, branchList));
    },
    otherBranch() {
      return this.branchList.filter(item => {
        return item.alias !== this.$route.params.branchAlias
      })
    },
    ...setComputed,
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll, { passive: false });
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll, { passive: false });
  },
  methods: {
    onClickdropDownBoxOutside() {
      this.dropDownIsOpen = false;
    },
    getExtrafields(item, field) {
      if(field in item.extrafields){
        return item.extrafields[field].value;
      }else{
        return ''
      }
    },
    getParams(item, field) {
      const extrafields = this.getExtrafields(item, field);
      if(extrafields) {
        if(field in extrafields.params){
          return extrafields.params[field];
        }else{
          return ''
        }
      }
    },
    goToBranch(alias) {
      this.$router.push({ name: 'branch', params: { branchAlias: alias } })
    },
    getBanner(dir) {
      return axios.post(`/media/folderFiles`, {
        dir: dir
      }).then((response) => {
        if(response.data.data.items > 0) {
          return response.data.data.items[0].images;
        }else {
          return ''
        }

      });
    },
    onScroll() {
      this.scrollTop = this.getScrollTop()
    },
    formatNtoBr(text) {
      return text.replace(/\r\n/g,"<br>").replace(/\n/g,"<br>");
    },
    getScrollTop
  },
}
</script>

<template>
  <Layout>
    <div :class="[$style.dropDownBox, { [$style.isOpen]: dropDownIsOpen, [$style.isScroll]: scrollTop > 70 }]" v-click-outside="onClickdropDownBoxOutside">
      <div :class="[$style.dropDown, { [$style.isOpen]: dropDownIsOpen }]">
        <div @click="dropDownIsOpen = !dropDownIsOpen" :class="$style.dropDownToggle"><span style="padding-right: 30px;">{{ $t('global.NAV_STORE') }}</span></div>
        <div v-if="dropDownIsOpen" :class="$style.dropDownMenu">
          <div :class="$style.menuGroupList">
            <template v-for="($key, $index) in menuGroupSort">
              <div v-if="menuGroup[$key]" :key="$index" :class="$style.menuGroupItem">
                <div :class="$style.menuTitle">{{$key}}</div>
                <div :class="$style.menuList">
                  <div v-for="(menuItem, $index) in menuGroup[$key]" :key="$index" :class="$style.menuItem">
                    <router-link
                      :to="{ name: 'branch', params: { branchAlias: menuItem.alias } }"
                      :active-class="$style.isActive"
                      :class="$style.menuLink">{{menuItem.title}}</router-link>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <section :class="$style.sectionBanner">
      <BranchBanner :list="banner" :title="branchItem.title"></BranchBanner>
    </section>
    <section :class="$style.sectionContent">
      <div class="container-branch">
        <div v-fade-up :class="$style.text" v-html="formatNtoBr(getExtrafields(branchItem, 'store_description'))"></div>
      </div>
    </section>
    <section :class="$style.sectionParams">
      <div :class="$style.paramList">
        <template v-for="(paramItem, $index) in paramsList">
          <div v-if="getExtrafields(branchItem, paramItem.alias) !== 0
          && getExtrafields(branchItem, paramItem.alias) !== '0'
          && getExtrafields(branchItem, paramItem.alias) !== ''
           && getExtrafields(branchItem, paramItem.alias) !== null"
               v-fade-up="{ delay: $index * 3 }"
               :class="$style.paramItem"
               :key="$index">
            <div :class="$style.icon"
                 :style="{ backgroundImage: `url(${paramItem.icon})` }"></div>
            <div :class="$style.title">{{paramItem.title}}</div>
            <div :class="$style.valueBox">
              <template v-if="paramItem.unit === 'boolean'">
                <div :class="$style.value">
                  <img src="@assets/images/branch/check.svg">
                </div>
              </template>
              <template v-else>
                <div :class="$style.value">
                  {{getExtrafields(branchItem, paramItem.alias)}}
                </div>
                <div v-if="$route.params.lang === 'zh-TW'" :class="$style.unit">{{paramItem.unit}}</div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </section>
    <section :class="$style.sectionInfo">
      <div class="container-branch">
        <div
          v-fade-up
          :class="$style.banner"
          :style="{ backgroundImage: `url(${currentWebsite.main}${getExtrafields(branchItem, 'banner_image')})` }"
        ></div>
        <div v-fade-up class="row flex-column flex-lg-row-reverse mx-0 align-items-center" :class="$style.infoUp">
          <div :class="$style.infoUpItemS" class="text-lg-left text-right mb-3 mb-lg-0">
            <a v-if="getExtrafields(branchItem, 'booking_link')"
               :target="getParams(branchItem, 'target')"
               :href="getExtrafields(branchItem,
            'booking_link')" :class="$style.iconBtn">
              <img src="@assets/images/icon/date.svg">
            </a>
            <a v-if="getExtrafields(branchItem, 'address')" target="_blank"
               :href="`https://www.google.com.tw/maps/place/${getExtrafields(branchItem, 'address')}`" :class="$style.iconBtn">
              <img src="@assets/images/icon/location.svg">
            </a>
          </div>
          <div :class="$style.infoUpItemB">
            <div :class="$style.title">
              {{ $t('global.ORANGE_SHABU') }} <span :class="$style.hr">|</span> {{branchItem.title}}</div>
          </div>
        </div>
        <hr v-fade-up>
        <div class="row mx-0 text-center text-lg-left" :class="$style.infoDown">
          <div v-fade-up :class="$style.infoDownItem">
            <div :class="$style.title">{{ $t('global.OPENING_HOURS') }}</div>
            <div :class="$style.infoList">
              <div v-if="getExtrafields(branchItem, 'service_week1') && getExtrafields(branchItem, 'service_hour1')"
                   :class="[$style.infoItem, $style.time]">
                <div :class="$style.title">{{getExtrafields(branchItem, 'service_week1')}}</div>
                <div :class="$style.content">{{getExtrafields(branchItem, 'service_hour1')}}</div>
              </div>
              <div v-if="getExtrafields(branchItem, 'service_week2') && getExtrafields(branchItem, 'service_hour2')"
                   :class="[$style.infoItem, $style.time]">
                <div :class="$style.title">{{getExtrafields(branchItem, 'service_week2')}}</div>
                <div :class="$style.content">{{getExtrafields(branchItem, 'service_hour2')}}</div>
              </div>
            </div>
          </div>
          <div v-fade-up="{ delay: 3 }" :class="$style.infoDownItem">
            <div :class="$style.title">{{ $t('global.CONTACT_INFO') }}</div>
            <div :class="$style.infoList">
              <div v-if="getExtrafields(branchItem, 'phone')"
                   :class="[$style.infoItem, $style.phone]">
                <div :class="$style.title">{{ $t('global.TEL') }}</div>
                <div :class="$style.content">
                  <a :href="`tel:${getExtrafields(branchItem, 'phone')}`">{{getExtrafields(branchItem, 'phone')}}</a>
                </div>
              </div>
              <div v-if="getExtrafields(branchItem, 'address')"
                   :class="[$style.infoItem, $style.address]">
                <div :class="$style.title">{{ $t('global.ADDRESS') }}</div>
                <div :class="$style.content">
                  <a target="_blank"
                    :href="`https://www.google.com.tw/maps/place/${getExtrafields(branchItem, 'address')}`">{{getExtrafields(branchItem, 'address')}}</a>
                </div>
              </div>
            </div>
          </div>
          <div v-fade-up="{ delay: 6 }" :class="$style.infoDownItem">
            <div :class="$style.title">{{ $t('global.TRANSPORTATION') }}</div>
            <div :class="$style.infoList">
              <div v-if="getExtrafields(branchItem, 'mass_transit')"
                   :class="[$style.infoItem, $style.traffic]">
                <div :class="$style.title">{{ $t('global.MRT') }}</div>
                <div :class="$style.content">{{getExtrafields(branchItem, 'mass_transit')}}</div>
              </div>
              <div v-if="getExtrafields(branchItem, 'driving')"
                   :class="[$style.infoItem, $style.traffic]">
                <div :class="$style.title">{{ $t('global.BY_CAR') }}</div>
                <div :class="$style.content">{{getExtrafields(branchItem, 'driving')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="otherBranch.length > 0"  :class="$style.sectionOther">
      <div v-fade-up :class="$style.sectionTitle">{{ $t('global.OTHER_STORES') }}</div>
      <div :class="$style.otherList">
        <template v-for="(item, $index) in otherBranch">
          <div
            v-if="item.alias !== $route.params.branchAlias && $index < 3"
            :key="$index"
            v-fade-up="{ delay: $index * 3 }"
            :class="$style.otherItem"
            @click="goToBranch(item.alias)"
          >
            <div :class="$style.imageBox"
                 :style="{ backgroundImage: `url(${currentWebsite.main}${getExtrafields(item,'other_image')})` }">
            </div>
            <div :class="$style.textBox">
              <div class="d-flex justify-content-between align-items-end">
                <div :class="$style.title">{{getExtrafields(item, 'other_title')}}</div>
                <div :class="$style.location">{{getExtrafields(item, 'store_district')}}</div>
              </div>
              <router-link
                :to="{ name: 'branch', params: { branchAlias: item.alias } }"
                :class="$style.moreLink">
                {{$t('global.LEARN_MORE')}}
              </router-link>
            </div>
          </div>
        </template>

      </div>
    </section>
  </Layout>
</template>
<style lang="scss" module>
  .dropDownBox {
    height: 110px;
    @include touch {
      height: 50px;
    }
    &.isScroll {
      &.isOpen {
        &:before {
          // top: 86px;
        }
      }
      .dropDown {
        // top: 86px;
        .dropDownToggle {
          // padding: 25px;
        }
      }

    }
    &.isOpen {
      &:before {
        content: '';
        background: rgba(0, 0, 0, 0.75);
        position: fixed;
        left: 0;
        top: 70px;
        width: 100vw;
        height: 100vh;
        z-index: 3;
        pointer-events: none;
      }
    }
    .dropDown {
      position: relative;
      // top: 138px;
      // left: 0;
      width: 100%;
      z-index: 3;
      background: #141414;
      color: #ece8de;
      transition: top 0.2s;
      @include touch {
        // top: 70px !important;
      }
      .dropDownToggle {
        padding: 34px 0;
        text-align: center;
        font-size: 28px;
        letter-spacing: 5px;
        position: relative;
        cursor: pointer;
        font-weight: 500;
        transition: padding 0.3s;
        @include touch {
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 3.24px;
          padding: 10px 0 !important;
        }
        &:after {
          content: '';
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAKCAYAAAC5Sw6hAAAAAXNSR0IArs4c6QAAAL1JREFUKBWVjzsOgkAQhjeQGBsSOxNLLSyNDSewpuMElF5CGxuvYOcJrDwAlaUUGh93Wb8xLoSBCEvyhZn/sSzGWruHM4TG86Ezgjuk3yrDUfA5h/wQLrAueywhyK22pfhnIBf88rtGDMN9IWuYSiB7EJRcrZjyzzdIKrU+4W3gBEHdURuBCbwgVpZByyCHgfZad4IzeMPcBZgTuELktF5vCkt4gtwwBrnluFdZhyiuoIAHTLXvtXNACouu0gdmmbFARBz56gAAAABJRU5ErkJggg==');
          width: 17px;
          height: 10px;
          position: absolute;
          top: 50%;
          left: 57%;
          margin-top: -4px;
          @include touch {
            left: 66%;
          }
        }

      }
      .dropDownMenu {

      }
      &.isOpen{
        background: #c0b4a7;
        color: #000;

        .dropDownToggle {
          &:after {
            transform: scaleY(-1);
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAKCAYAAAC5Sw6hAAAAAXNSR0IArs4c6QAAARBJREFUKBVj1FKV6mb4z6Bz7c4zHwYGhr9ATDRQUBAQ4GblOv7/P2MdWJOWivRiECbaBKBCBQUGDi1V6ZPaKlLZyPqYtVSktgNxI7IgHjYTRL10G4YamA2aKlLJGJJoAkBXzAFhNGEEF+RnbVWpa0BFfghRVBbQJQ1AvBEoyoQsg8J58ODDhz8/frn8Z2Ds1VaUMENWCGKDXcvI4MzI/iwUyP2HLo/B11ERU9ZWkb6jriilDpMEuVJbVfqiuroIL0wMmWZE5iCzgc43ZGBgXPn3508HFhYWmf/MzEuZ/v62uXL/1UtkdTA2ToNACjSVpFyYmBj7/jP8Z//D9Nfz1q2X92AaSaaB6StUW1Fan5BGAO/hQkUfz+PmAAAAAElFTkSuQmCC');
          }
        }
        .dropDownMenu {
          text-align: center;
          margin: 2rem 0 0;
          padding-bottom: 4rem;
          @include touch {
            margin: 16px 0 0;
            padding-bottom: 34px;
          }
          .menuTitle {
            color: #fbfbf7;
            font-size: 18px;
            letter-spacing: 4px;
            position: relative;
            margin-bottom: 2rem;
            line-height: 28px;
            font-weight: 600;
            &:before {
              content: "";
              bottom: -0.5rem;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              width: 160px;
              height: 1px;
              background: #fbfbf7;
            }
          }
          .menuGroupList {
            display: flex;
            justify-content: center;
            @include touch {
              flex-direction: column;
              max-height: calc(100vh - 120px);
              overflow: scroll;
              justify-content: start;
            }
            .menuGroupItem {
              width: 240px;
              margin: 0 5rem;
              @include touch {
                margin: 0 auto;
                &:not(:last-child) {
                  margin-bottom: 2rem;
                }
              }
            }
          }
          .menuList {
            .menuItem {
              margin-bottom: 20px;
              .menuLink {
                color: #000;
                font-size: 20px;
                line-height: 28px;
                font-weight: 500;
                letter-spacing: 0.36px;
                @include touch {
                  font-size: 18px;
                }
                &:hover, &.isActive {
                  text-decoration: none;
                  color: #fbfbf7;
                }
              }
            }
          }
        }
      }
    }

  }
  .sectionBanner {
    // background-image: url('@assets/images/booking/booking_banner.jpg');
    // height: 100vh;
    // height: calc(100vh - 130px);
    background-position: center;
    color: #ece8de;
    background-repeat: no-repeat;
    position: relative;
    .title {
      writing-mode: vertical-lr;
      font-size: 48px;
      letter-spacing: 10px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: calc((100vh - 130px) / 2);
      margin: 0;
      margin-top: -181px;
    }
    .content {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 1px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: calc((100vh - 130px) / 2);
      margin: 0;
      margin-top: 20px;
      text-align: center;
    }
  }
  .sectionContent {
    padding: 120px 0 135px;
    background-color: #e2ded6;
    @include touch {
      padding: 60px 0;
    }
    .text {
      width: 802px;
      margin: 0 auto;
      padding: 0 1.5rem;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 1.92px;
      text-align: center;
      font-weight: 500;
      color: #000;
      p {
        margin-bottom: 0;
      }
      @include touch {
        padding: 0;
        width: auto;
        line-height: 24px;
        font-size: 14px;
        letter-spacing: 1.82px;
      }
    }
  }
  .sectionParams {
    padding: 0 0 210px;
    background-color: #e2ded6;
    @include touch {
      padding: 0 0 5rem;
    }
    .paramList {
      display: flex;
      justify-content: center;
      .paramItem {
        // width: 80px;
        padding: 0 3rem;
        text-align: center;
        color: #1a1311;
        @include touch {
          margin: 0 10px;
        }
        .icon {
          width: 66px;
          height: 66px;
          background-position: center;
          background-repeat: no-repeat;
          margin: 0 auto 1.5rem;
          @include touch {
            background-size: 67%;
            margin: 0 auto 0.5rem;
          }
        }
        .title {
          font-size: 14px;
          line-height: 16.8px;
          letter-spacing: 1.47px;
          text-align: center;
          font-family: PingFangTC-regular, Noto Sans TC, sans-serif;
          margin-bottom: 2rem;
          white-space: nowrap;
          @include touch {
            font-size: 12px;
            margin-bottom: 1rem;
          }
        }
        .valueBox {
          font-family: PingFangTC-Light, Noto Sans TC, sans-serif;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          .value {
            font-size: 30px;
            line-height: 36px;
            letter-spacing: 2.25px;
          }
          .unit {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 1px;
            font-weight: 300;
            position: relative;
            top: -5px;
          }

        }
      }
    }
  }
  .sectionInfo {
    background-color: #e2ded6;
    color: #000;
    padding-bottom: 124px;
    @include touch {
      padding-bottom: 30px;
    }
    .banner {
      padding-top: 41.119%;
      margin-bottom: 130px;
      background-position: center;
      background-size: cover;
      @include touch {
        padding-top: 68.36%;
        margin-bottom: 30px;
      }
    }
    .infoUp {
      .infoUpItemB {
        -webkit-box-flex: 0;
        flex: 0 0 63.324%;
        max-width: 63.324%;
        position: relative;
        width: 100%;
        @include touch {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .infoUpItemS {
        -webkit-box-flex: 0;
        flex: 0 0 36.676%;
        max-width: 36.676%;
        position: relative;
        width: 100%;
        @include touch {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .title {
        font-family: Palatino, 'Noto Serif TC', serif;
        font-size: 32px;
        letter-spacing: 1.92px;
        font-weight: 500;
        @include touch {
          font-size: 24px;
          letter-spacing: 1.44px;
        }
      }
      .iconBtn {
        -webkit-appearance: none;
        background: transparent;
        border: 0px;
        height: 38px;
        width: 38px;
        padding: 0;
        margin-right: 1rem;
        @include touch {
          margin-left: 1rem;
          margin-right: 0;
        }
        &:hover {
          opacity: 0.8;
        }
        &:active {
          opacity: 0.4;
        }
      }
    }
    hr {
      border-color: #000;
      margin-top: 20px;
      margin-bottom: 34px;
      @include touch {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    .infoDown {
      .infoDownItem {
        -webkit-box-flex: 0;
        flex: 0 0 31.662%;
        max-width: 31.662%;
        position: relative;
        width: 100%;
        @include touch {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .infoDownItem:last-child {
        -webkit-box-flex: 0;
        flex: 0 0 36%;
        max-width: 36%;
        position: relative;
        width: 100%;
        @include touch {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .title {
        font-size: 23px;
        line-height: 65px;
        letter-spacing: 1.38px;
        font-weight: 600;
        margin-bottom: 1rem;
        .hr {
          font-family: 'Noto Serif TC', serif;
          padding-left: 10px;
          padding-right: 10px;
        }
        @include touch {
          margin-bottom: 0;
        }
      }
      .infoList {
        @include touch {
          margin-bottom: 30px;
        }
        .infoItem {
          margin-bottom: 2rem;
          @include touch {
            margin-bottom: 1rem;
          }
          &.time {
            .title {
              font-size: 15px;
            }
            .content {
              font-size: 18px;
              font-weight: 400;
            }
          }
          &.phone {
            .title {

            }
            .content {
              font-size: 18px;
              font-weight: 400;
            }
          }
          &.address, &.traffic {
            .title {

            }
            .content {

            }
          }
          .title {
            font-family: Palatino, 'Noto Serif TC', serif;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 1.12px;
            font-weight: 700;
            margin-bottom: 0rem;
            @include touch {
              font-weight: 600;
            }
          }
          .content {
            font-family: Palatino, 'Noto Serif TC', serif;
            font-size: 17px;
            line-height: 30px;
            letter-spacing: 0.85px;
            font-weight: 300;
            a {
              color: #000;
              &:hover, &:focus {
                outline: none;
                color: #000;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
  .sectionOther {
    background-color: #e2ded6;
    padding: 101px 0 90px;
    @include touch {
      padding: 0 0 80px;
    }
    .sectionTitle {
      font-size: 30px;
      letter-spacing: 1.8px;
      line-height: 28px;
      color: #000;
      font-weight: 500;
      text-align: center;
      margin-bottom: 2.5rem;
      @include touch {
        margin-bottom: 28px;
      }
    }
    .otherList {
      display: flex;
      justify-content: center;
      @include touch {
        flex-direction: column;
      }
      .otherItem {
        cursor: pointer;
        width: 399px;
        margin: 0 30px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        @include touch {
          margin: 0 30px 40px;
          width: 80%;
          width: calc(100vw - 60px);
        }
        .imageBox {
          background-size: cover;
          background-position: center;
          padding-top: 62.66%;
          background: #eee;
          background-size: cover;
          @include touch {
            padding-top: 62.39%;
          }
        }
        .textBox {
          padding: 27px;
          background-color: #ece8de;
          @include touch {
            padding: 20px;
          }
          .title {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 2.4px;
            color: #000;
            font-weight: 500;
            @include touch {
              line-height: 24px;
            }
          }
          .location {
            color: #797979;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0.52px;
            font-weight: 500;
            min-width: 68px;
            @include touch {
              font-size: 12px;
              line-height: 15px;
            }
          }
          .moreLink {
            margin-top: 50px;
            font-family: PingFangTC-Light, Noto Sans TC, sans-serif;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 1.12px;
            color: #2b251b;
            display: inline-block;
            position: relative;
            @include touch {
              margin-top: 46px;
              line-height: 16px;
            }
            &:hover {
              text-decoration: none;
            }
            &:before {
              content: '';
              width: 100%;
              height: 1px;
              background: #2b251b;
              bottom: 0;
              left: 0;
              display: block;
              position: absolute;
            }
          }
        }
      }
    }
  }
</style>
