<script>
// https://github.com/surmon-china/vue-awesome-swiper
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

import { setComputed } from '@state/helpers'

export default {
  components: { swiper, swiperSlide },
  computed: {
    ...setComputed,
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    const $self = this;
    return {
      window_w: 1920,
      pageCount: 0,
      swiperOption: {
        slidesPerView: 1,
        speed: 600,
        direction: 'horizontal',
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        on: {
          slideChange () {
            $self.pageCount = ( this.activeIndex % $self.list.length ) || 0
          }
        },
      }
    }
  },
  mounted() {
    this.getWindowWidth();
    window.addEventListener('resize', this.getWindowWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    getBackground(item) {
      const { image } = item;
      const imageM = item.image_m ? item.image_m : item.image;

      return this.getWindowWidth() < 992 ? imageM : image;
    },
    getWindowWidth() {
      return document.body.clientWidth;
    },
    setSlide(index) {
      this.$refs.timelineSwiper.swiper.slideTo(index, 1000, false);
    },
    onNext() {
      if(this.pageCount < this.list.length - 1) {
        this.setSlide(this.pageCount + 1);
      }else {
        this.setSlide(0);
      }
    },
    onPrev() {
      if(this.pageCount > 0) {
        this.setSlide(this.pageCount - 1);
      }else {
        this.setSlide(this.list.length);
      }
    },
  }
};
</script>
<template>
  <div class="position-relative">
    <div :class="$style.carouselContent">
      <h2 v-fade-up :class="$style.title">{{ $t('global.ORANGE_SHABU') }}</h2>
      <h3 v-fade-up :class="$style.subTitle">{{title}}</h3>
    </div>
    <swiper
      :class="$style.carousel"
      :options="swiperOption"
      ref="timelineSwiper">
      <!-- slides -->
      <swiper-slide
        v-for="(slide, $index) in list"
        :key="$index"
        :class="$style.carouselSlide"
        :style="{ backgroundImage: `url(${currentWebsite.main}${ slide.path })` }"
      ></swiper-slide>
    </swiper>
    <div :class="$style.paginationBox">
      <div :class="$style.paginationList">
        <button
          v-for="(slide, $index) in list"
          :key="slide.title"
          @click="setSlide($index)"
          :class="[$style.paginationItem, pageCount === $index ? $style.isActive : '']"
        >
          <span :class="$style.paginationDot"></span>
        </button>
      </div>
    </div>
    <div :class="$style.navigationBox">
      <button @click="onPrev" :class="[$style.navigationPrev, $style.navigationBtn]">
        <i :class="$style.arrowIcon"></i>
      </button>
      <button @click="onNext" :class="[$style.navigationNext, $style.navigationBtn]">
        <i :class="$style.arrowIcon"></i>
      </button>
    </div>
  </div>
</template>
<style lang="scss" module>

  .carousel {
    min-height: 600px;
    height: calc(100vh - 240px);
    @include touch {
      min-height: auto;
      height: calc(100vh - 120px);
    }
  }
  .carouselSlide {
    background-repeat: no-repeat;
    background-position: center !important;
    background-size: cover !important;
    position: relative;
    background: #000;
    background-repeat: no-repeat;
    @include touch {
      background-position: bottom !important;
    }
    &:before {
      content: "";
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.5;
      background: #000;
      position: absolute;
    }
  }
  .carouselContent {
    font-family: 'Noto Serif TC', sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    text-align: center;
    z-index: 2;
    transform: translate(-50%, -50%);
    @include touch {
      width: 100%;
    }
    .title {
      font-size: 44px;
      line-height: 65px;
      letter-spacing: 2.64px;
      @include touch {
        font-size: 38px;
        line-height: 60px;
        letter-spacing: 2.28px;
      }
    }
    .subTitle {
      font-size: 26px;
      line-height: 65px;
      letter-spacing: 1.56px;
      font-weight: 600;
      @include touch {
        font-size: 22px;
        line-height: 60px;
        letter-spacing: 1.32px;
      }
    }
  }
  .paginationBox {
    position: absolute;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    @include touch {
      bottom: 0rem;
      top: auto;
      left: 50%;
      transform: translateX(-50%);
    }
    .paginationList {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include touch {
        justify-content: center;
        flex-direction: row;
      }
      .paginationDot {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 100%;
        border: 1px solid #ad9d8b;
        @include touch {
          margin: 1rem;
        }
      }
      .paginationText {
        color: #ad9d8b;
        padding: 0 1rem;
        font-size: 0.9rem;
        display: none;
        @include touch {
          display: none !important;
        }
      }
      .paginationItem {
        background: transparent;
        appearance: none;
        border: 0 solid transparent;
        padding: 0.5rem 0;
        &:focus {
          outline: none;
          box-shadow: unset;
        }
        &:hover, &.isActive {
          .paginationDot {
            background: #ad9d8b;
          }
          .paginationText {
            display: inline;
          }
        }
      }
    }
  }
  .navigationBox {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 2;
    display: none;
    @include touch {
      display: block;
      bottom: 50%;
      margin-bottom: -30px;
    }
    .navigationBtn {
      width: 70px;
      height: 70px;
      position: absolute;
      bottom: 0;
      color: #f5f1ed;
      appearance: none;
      background: transparent;
      border: 0px solid transparent;
      margin-top: -50px;
      text-align: center;
      background: #1d1d1d;
      padding: 0;
      @include touch {
        font-size: 1.5rem;
        background: transparent;
      }
      &:focus {
        outline: none;
      }
      &:active {
        background: lighten(#1d1d1d, 10%);
        @include touch {
          opacity: 0.5;
          background: transparent;
        }
      }
      .arrowIcon {
        &:before {
          content: '';
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNyA0NCI+PHBhdGggZD0iTTAsMjJMMjIsMGwyLjEsMi4xTDQuMiwyMmwxOS45LDE5LjlMMjIsNDRMMCwyMkwwLDIyTDAsMjJ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+);
          width: 15px;
          height: 30px;
          display: block;
          margin: auto;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 0.6;
        }
      }
    }
    .navigationPrev {
      right: 70px;
      @include touch {
        left: 0px;
        right: auto;
      }
    }
    .navigationNext {
      right: 0;
      @include touch {
        right: 0;
      }
      .arrowIcon {
        &:before {
          transform: scaleX(-1);
        }
      }
    }
  }
</style>
